import { LoadingSvg } from '@/assets/icons/comm/LoadingSvg';
import BaseInput from '@/components/base/baseInput';
import BaseModal from '@/components/base/baseModal';
import { VerifiedCodeEnum, VerifiedCodeTypeEnum } from '@/enums/businessEnum';
import { toast } from '@/layouts/components/ToastMessage';
import { sendMailApi } from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import { Button, useDisclosure } from '@nextui-org/react';
import { ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default ({
  user,
  children,
  newEmail,
  setNewEmail,
  oldCaptcha,
  setOldCaptcha,
  captcha,
  setCaptcha,
  countdownOld,
  countdown,
  modifyEmail,
  setTargetDateOld,
  setTargetDate,
}: IProps) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  // 区分是 修改还是新增
  const [isModify,setIsModify] = useState(false) 
  // 发送验证码
  const { run: sendMailApiRequest, loading: sendMailLoading } = useReq(
    sendMailApi,
    {
      manual: true,
      loadingDefault: false,
      onSuccess: (res) => {
        if(isModify){
          setTargetDateOld(Date.now()+ 30000);
          setIsModify(false)
        }else{
          setTargetDate(Date.now() + 30000);
        }
        toast.success(t('发送成功'));
      },
      onError() {
        setTargetDate(Date.now());
        setTargetDateOld(Date.now());
        setIsModify(false)
      },
    },
  );
  // 用于修改邮箱
  const sendSelfMailLoading =useMemo(()=>{
    if(isModify&&user?.email){
      return sendMailLoading 
    }
    return false
  },[sendMailLoading])

  return (
    <>
      <div
        onClick={() => {
          onOpen();
        }}
      >
        {children}
      </div>
      <BaseModal
        isOpen={isOpen}
        onClose={onClose}
        title={!user?.email ? t('绑定邮箱') : t('修改邮箱')}
      >
        <div className="mt-4 mb-10 px-4 text-foreground ">
          <div className="mt-4">
            {/* 新邮箱  【未設置邮箱】 */}
            {!user?.email && (
              <BaseInput
                className="mt-4"
                value={newEmail}
                classNames={{
                  inputWrapper:
                    ' bg-backgroundAuxiliaryColor  !border-0 !shadow-none',
                }}
                onChange={(e) => setNewEmail(e.target.value)}
                isRequired
                label={t('邮箱')}
              />
            )}
            {/* 邮箱/手机号输入框  【已設置邮箱】 */}
            {user?.email && (
              <div className=" relative mt-4">
                <BaseInput
                  value={oldCaptcha}
                  onChange={(e) => setOldCaptcha(e.target.value)}
                  isRequired
                  label={t('旧邮箱验证码')}
                  classNames={{
                    inputWrapper:
                      ' bg-backgroundAuxiliaryColor  !border-0 !shadow-none',
                  }}
                  isShowClear={false}
                />
                <Button
                  spinner={<LoadingSvg />}
                  isLoading={sendSelfMailLoading}
                  onClick={() => {
                    if (countdownOld !== 0) {
                      return toast.error(
                        t(
                          '验证码已发送，请您耐心等待{{times}}秒后再试。感谢您的理解与配合！',
                          {
                            times: 30,
                          },
                        ),
                      );
                    }
                    setIsModify(true)
                    // type是后端区分验证码在哪里发送的
                    sendMailApiRequest({
                      to: user?.email.trim(),
                      sendType: VerifiedCodeTypeEnum.EMAIL,
                      type: VerifiedCodeEnum.UPDATE_PROFILE,
                    });
                  }}
                  className="focus:outline-none !bg-[transparent] text-darkBrandColor text-base w-[60px] absolute right-1 top-[50%] translate-y-[-50%] cursor-pointer"
                  type="button"
                >
                  {countdownOld !== 0 ? (
                    <span className="text-foreground ">
                      {Math.round(countdownOld / 1000)}
                    </span>
                  ) : (
                    t('发送')
                  )}
                </Button>
              </div>
            )}
            {/* 新邮箱  【已設置邮箱】*/}
            {user?.email && (
              <BaseInput
                className="mt-4"
                classNames={{
                  inputWrapper:
                    ' bg-backgroundAuxiliaryColor  !border-0 !shadow-none',
                }}
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
                isRequired
                label={t('新邮箱')}
              />
            )}
            {/* 验证码输入框 */}
            <div className=" relative mt-4">
              <BaseInput
                value={captcha}
                onChange={(e) => setCaptcha(e.target.value)}
                isRequired
                type="text"
                className="mt-4"
                isShowClear={false}
                classNames={{
                  clearButton: 'block opacity-100',
                  inputWrapper:
                    ' bg-backgroundAuxiliaryColor  !border-0 !shadow-none',
                }}
                label={user?.email?t("新邮箱验证码"):t('验证码')}
              />
              <Button
                spinner={<LoadingSvg />}
                isLoading={!isModify&&sendMailLoading}
                onClick={() => {
                  const emailRegex =
                    /^[a-zA-Z0-9_.!#$%*-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,9}$/;
                  if (!newEmail.trim()) {
                    return toast.error(t('请输入邮箱'));
                  }
                  if (!emailRegex.test(newEmail)) {
                    return toast.error(t('请输入正确的邮箱'));
                  }
                  if (countdown !== 0) {
                    return toast.error(
                      t(
                        '验证码已发送，请您耐心等待{{times}}秒后再试。感谢您的理解与配合！',
                        {
                          times: 30,
                        },
                      ),
                    );
                  }

                  // type是后端区分验证码在哪里发送的
                  sendMailApiRequest({
                    to: newEmail.trim(),
                    sendType: VerifiedCodeTypeEnum.EMAIL,
                    type: VerifiedCodeEnum.UPDATE_PROFILE,
                  });
                }}
                className="focus:outline-none !bg-[transparent] text-darkBrandColor text-base w-[60px] absolute right-1 top-[50%] translate-y-[-50%] cursor-pointer"
                type="button"
              >
                {countdown !== 0 ? (
                  <span className="text-foreground ">
                    {Math.round(countdown / 1000)}
                  </span>
                ) : (
                  t('发送')
                )}
              </Button>
            </div>
            {/*提交*/}
            <Button
              className="mainColorButton w-full mt-4"
              onClick={() => {
                modifyEmail();
              }}
            >
              {t('确认修改')}
            </Button>
          </div>
        </div>
      </BaseModal>
    </>
  );
};

interface IProps {
  user: any; // 用户信息
  children: ReactNode;
  newEmail: string;
  setNewEmail: (value: string) => void;
  oldCaptcha: string;
  setOldCaptcha: (value: string) => void;
  captcha: string;
  setCaptcha: (value: string) => void;
  countdownOld: number;
  countdown: number;
  modifyEmail: () => void;
  setTargetDateOld: (value: number) => void;
  setTargetDate: (value: number) => void;
}
