import React from 'react';
import type { SVGProps } from 'react';

export function MaterialSymbolsContactPhone(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M2 21q-.825 0-1.412-.587T0 19V5q0-.825.588-1.412T2 3h20q.825 0 1.413.588T24 5v14q0 .825-.587 1.413T22 21zm7-7q1.25 0 2.125-.875T12 11q0-1.25-.875-2.125T9 8q-1.25 0-2.125.875T6 11q0 1.25.875 2.125T9 14m10 4l2-2l-1.5-2h-1.65q-.15-.45-.25-.962T17.5 12q0-.525.1-1.012t.25-.988h1.65L21 8l-2-2q-1.35 1.05-2.175 2.663T16 12q0 1.725.825 3.338T19 18M2.1 19h13.8q-1.05-1.875-2.9-2.937T9 15q-2.15 0-4 1.063T2.1 19"
      ></path>
    </svg>
  );
}