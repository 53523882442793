import {IconParkSolidEditName} from '@/assets/icons/mine/IconParkSolidEditName';
import {MaterialSymbolsAttachEmail} from '@/assets/icons/mine/MaterialSymbolsAttachEmail';
import {MaterialSymbolsContactPhone} from '@/assets/icons/mine/MaterialSymbolsContactPhone';
import BaseInput from '@/components/base/baseInput';
import BaseUpload from '@/components/base/baseUpload';
import ModifyUserInformationEmail from '@/pages/mine/modifyUserInformation/components/ModifyUserInformationEmail';
import ModifyUserInformationPhone from '@/pages/mine/modifyUserInformation/components/ModifyUserInformationPhone';
import { history, useModel } from '@@/exports';
import {Button, Image} from '@nextui-org/react';
import {useTranslation} from 'react-i18next';
import { useState } from 'react';
import { useCountDown, useReactive, useUpdateEffect } from 'ahooks';
import { useLocalStorage } from '@/hooks/useStorage';
import CacheEnum from '@/enums/cacheEnum';
import { useReq } from '@/services/net/request';
import {
  modifyEmailApi,
  modifyMobilePhoneNumberApi,
  modifyUserInformationApi,
  sendMailApi,
} from '@/services/api/requestApi';
import { toast } from '@/layouts/components/ToastMessage';
import PageEnum from '@/enums/pageEnum';
import { WAY_TO_REGISTER } from '@/pages/public/register/useStateHooks';

export default () => {
  const { user, requestUserInfo } = useModel('user') || {};
  const { currentAreaCode } = useModel('areaCode');
  const { t } = useTranslation();
  const form = useReactive({
    name: user.name,
    avatar: user?.avatar,
    email: user?.email,
    phone: user?.phone,
  })

  const [oldCaptcha, setOldCaptcha] = useState('');
  const [targetDateOld, setTargetDateOld] = useState<number>();
  useUpdateEffect(() => {
    form.avatar = user?.avatar;
    form.name = user?.name;
    form.phone = user?.phone;
    form.email = user?.email;

  }, [user]);
  /*-----------------修改邮箱-----------------------*/
  const [newEmail, setNewEmail] = useState('');
  const [newPhone, setNewPhone] = useState('');
  const [captcha, setCaptcha] = useState('');
  const [countdownOld] = useCountDown({
    targetDate: targetDateOld,
    onEnd: () => {},
  });
  //手机验证码
  const [targetDate, setTargetDate] = useLocalStorage({
    key: CacheEnum.APP_MODIFY_CODE,
    defaultValue: 0,
  });
  const [countdown] = useCountDown({
    targetDate,
    onEnd: () => {
    },
  });
  // 注册邮箱
  const [targetDateEmail, setTargetDateEmail] = useLocalStorage({
    key: CacheEnum.APP_MODIFY_CODE_EMAIL,
    defaultValue: 0,
  });
  const [countdownEmail] = useCountDown({
    targetDate: targetDateEmail,
    onEnd: () => {
    },
  });

  // 修改邮箱请求
  const { run: modifyEmailReq } = useReq(modifyEmailApi, {
    manual: true,
    async onSuccess() {
      await requestUserInfo?.({ noFund: true });
      toast.success(t('修改成功'));
      history.replace(PageEnum.MINE);
    },
  });
  //修改邮箱
  const modifyEmail = () => {
    modifyEmailReq({
      oldEmail: user.email,
      oldCode: oldCaptcha,
      newEmail: newEmail,
      code: captcha,
    });
  };
  /*-----------------修改手机号-----------------------*/
  // 修改邮箱请求
  const { run: modifyMobilePhoneNumberReq } = useReq(
    modifyMobilePhoneNumberApi,
    {
      manual: true,
      async onSuccess() {
        await requestUserInfo?.({ noFund: true });
        toast.success(t('修改成功'));
        history.replace(PageEnum.MINE);
      },
    },
  );
  //修改手机号
  const modifyMobilePhoneNumber = () => {
    modifyMobilePhoneNumberReq({
      oldPhone: user?.phone,
      oldAreaCode: user?.areaCode,
      oldCode: oldCaptcha,
      phone: newPhone,
      code: captcha,
      areaCode: (currentAreaCode as any)?.areaCode,
    });
  };
  /*-----------------修改用户头像和名称-----------------------*/
  const { run: modifyUserInformationReq } = useReq(modifyUserInformationApi, {
    manual: true,
    async onSuccess() {
      await requestUserInfo?.({ noFund: true });
      toast.success(t('修改成功'));
      history.replace(PageEnum.MINE);
    },
  });
  const modifyUserInformation = () => {
    modifyUserInformationReq({
      avatar: form.avatar,
      name: form.name,
    });
  };
    return (
        <div>
            <div className="px-4">
                <div className="my-3 mt-8">
                    <div className="rounded-full w-[80px] h-[80px] mx-auto overflow-hidden">
                        <BaseUpload
                            minHeight="100%"
                            currentUrls={form.avatar}
                            preview={
                                <div className="w-full h-full">
                                    <Image
                                        classNames={{
                                            wrapper: '!max-w-[100%] !h-full ',
                                        }}
                                        className="w-full h-full cursor-pointer object-cover"
                                        src={form.avatar}
                                    />
                                </div>
                            }
                            onChange={(url:string) => {
                              form.avatar = url
                            }}
                        >                                
                        <Image
                          className="w-[80px] h-[80px] flex-shrink-0 rounded-full object-cover"
                          src={require('@/assets/img/defaultAvatar.png')}
                        />
                            {/* <div
                                className="absolute cursor-pointer left-0 right-0 top-0 bottom-0 bg-backgroundAuxiliaryColor flex justify-center items-center text-center">
                                {t('上传头像')}
                            </div> */}
                        </BaseUpload>
                    </div>
                </div>
                <div className="my-3">
                    <div className="flex items-center font-bold">{t('昵称')}</div>
                    <BaseInput
                        className="mt-2"
                        placeholder={t('昵称')}
                        value={form.name}
                        onChange={(e) => {
                          form.name = e.target.value
                        }}
                        startContent={
                            <IconParkSolidEditName className="text-auxiliaryTextColor"/>
                        }
                    />
                </div>
                <div className="my-3 relative">
                    <div className="flex items-center font-bold">{t('邮箱')}</div>
                    <BaseInput
                        className="mt-2"
                        isShowClear={false}
                        placeholder={t('邮箱')}
                        readOnly={true}
                        value={form.email}
                        classNames={{
                            inputWrapper:
                                'border-1 border-borderColor group-data-[focus=true]:border-borderColor ',
                        }}
                        onChange={(e) => {
                          form.email = e.target.value
                        }}
                        startContent={
                            <MaterialSymbolsAttachEmail className="text-auxiliaryTextColor"/>
                        }
                    />
                    <ModifyUserInformationEmail
                      user={user}
                      newEmail={newEmail}
                      setNewEmail={setNewEmail}
                      oldCaptcha={oldCaptcha}
                      setOldCaptcha={setOldCaptcha}
                      captcha={captcha}
                      setCaptcha={setCaptcha}
                      countdownOld={countdownOld}
                      countdown={countdown}
                      modifyEmail={modifyEmail}
                      setTargetDate={setTargetDate}
                      setTargetDateOld={setTargetDateOld}
                    >
                        <div className="absolute bottom-[12px] right-3 cursor-pointer">
                            {t('点击修改')}
                        </div>
                    </ModifyUserInformationEmail>
                </div>
                <div className="my-3 relative">
                    <div className="flex items-center font-bold">{t('手机')}</div>
                    <BaseInput
                        className="mt-2"
                        placeholder={t('手机')}
                        isShowClear={false}
                        classNames={{
                            inputWrapper:
                                'border-1 border-borderColor group-data-[focus=true]:border-borderColor ',
                        }}
                        readOnly={true}
                        value={form.phone}
                        onChange={(e) => {
                          form.phone = e.target.value
                        }}
                        startContent={
                            <MaterialSymbolsContactPhone className="text-auxiliaryTextColor "/>
                        }
                    />
                    <ModifyUserInformationPhone
                        user={user}
                        newPhone={newPhone}
                        setNewPhone={setNewPhone}
                        countdownOld={countdownOld}
                        oldCaptcha={oldCaptcha}
                        setOldCaptcha={setOldCaptcha}
                        captcha={captcha}
                        setCaptcha={setCaptcha}
                        countdown={countdown}
                        modifyMobilePhoneNumber={modifyMobilePhoneNumber}
                        setTargetDate={setTargetDate}
                        setTargetDateOld={setTargetDateOld}
                    >
                        <div className="absolute bottom-[12px] right-3 cursor-pointer">
                            {t('点击修改')}
                        </div>
                    </ModifyUserInformationPhone>
                </div>
                <Button
                    className="mainColorButton w-full mt-4"
                    onClick={() => {
                        modifyUserInformation();
                    }}
                >
                    {t('提交')}
                </Button>
            </div>
        </div>
    );
};
