import { FluentTriangleDown12Filled } from '@/assets/icons/comm/FluentTriangleDown12Filled';
import { LoadingSvg } from '@/assets/icons/comm/LoadingSvg';
import BaseInput from '@/components/base/baseInput';
import BaseModal from '@/components/base/baseModal';
import { VerifiedCodeEnum, VerifiedCodeTypeEnum } from '@/enums/businessEnum';
import PageEnum from '@/enums/pageEnum';
import { toast } from '@/layouts/components/ToastMessage';
import { sendMailApi } from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import { history } from '@@/core/history';
import { useModel } from '@@/exports';
import useUrlState from '@ahooksjs/use-url-state';
import { Button, Image, useDisclosure } from '@nextui-org/react';
import { useMount } from 'ahooks';
import { ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export default ({
  user,
  children,
  newPhone,
  setNewPhone,
  oldCaptcha,
  setOldCaptcha,
  captcha,
  setCaptcha,
  countdownOld,
  countdown,
  modifyMobilePhoneNumber,
  setTargetDateOld,
  setTargetDate,
}: IProps) => {
  const { t } = useTranslation();
  const [urlState] = useUrlState<any>({});
  const { isKeepAlive } = urlState || {};
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { currentAreaCode } = useModel('areaCode');
  useMount(() => {
    // if (isKeepAlive) {
    //   onOpen();
    // }
  });
    // 区分是 修改还是新增
    const [isModify,setIsModify] = useState(false) 
  // 发送验证码
  const { run: sendMailApiRequest, loading: sendMailLoading } = useReq(
    sendMailApi,
    {
      manual: true,
      loadingDefault: false,
      onSuccess: (res) => {
        if(isModify){
          setTargetDateOld(Date.now()+ 30000);
          setIsModify(false)
        }else{
          setTargetDate(Date.now() + 30000);
        }
        toast.success(t('发送成功'));
      },
      onError() {
        setTargetDate(Date.now());
        setTargetDateOld(Date.now());
        setIsModify(false)
      },
    },
  );
    // 用于修改邮箱
    const sendSelfMailLoading =useMemo(()=>{
      if(isModify&&user?.phone){
        return sendMailLoading 
      }
      return false
    },[sendMailLoading])
  
  return (
    <>
      <div
        onClick={() => {
          onOpen();
        }}
      >
        {children}
      </div>
      <BaseModal isOpen={isOpen} onClose={onClose}>
        <div className="mt-4 mb-10 px-4 text-foreground ">
          <div className="flex justify-center w-full text-base  text-backContrastColor">
            <span>{!user?.phone ? t('绑定手机号') : t('修改手机号码')}</span>
          </div>
          <div className="mt-4">
            {/* 邮箱/手机号输入框【未設置手機號】 */}
            {!user?.phone && (
              <div className="flex items-center bg-backgroundAuxiliaryColor border-0 border-borderColor rounded-md">
                <div
                  className="flex items-center  h-full sm:cursor-pointer"
                  onClick={() => {
                    history.push(
                      `${PageEnum.CHOOSE_AREA}?targetUrl=${
                        location.pathname
                      }&isKeepAlive=${1}`,
                    );
                  }}
                >
                  <Image
                    classNames={{
                      wrapper:
                        'w-[20px] ml-2  h-[20px] flex-shrink-0 rounded-full object-cover',
                    }}
                    className="w-[20px] h-[20px] flex-shrink-0 rounded-full  object-cover"
                    src={(currentAreaCode as any)?.iconUrl}
                  />
                  <span>+{(currentAreaCode as any)?.areaCode}</span>
                  <FluentTriangleDown12Filled
                    className="text-backContrastColor ml-2"
                    width="8px"
                    height="8px"
                  />
                </div>
                <BaseInput
                  value={newPhone}
                  classNames={{
                    inputWrapper:
                      ' bg-backgroundAuxiliaryColor  !border-0 !shadow-none',
                  }}
                  onChange={(e) => setNewPhone(e.target.value)}
                  isRequired
                  label={t('手机号码')}
                />
              </div>
            )}
            {/* 邮箱/手机号输入框 【已設置手機號】*/}
            {user?.phone && (
              <div className=" relative mt-4">
                <BaseInput
                  value={oldCaptcha}
                  onChange={(e) => setOldCaptcha(e.target.value)}
                  classNames={{
                    inputWrapper:
                      ' bg-backgroundAuxiliaryColor  !border-0 !shadow-none',
                  }}
                  isRequired
                  label={t('旧手机验证码')}
                  isShowClear={false}
                />
                <Button
                  spinner={<LoadingSvg />}
                  isLoading={sendSelfMailLoading}
                  onClick={() => {
                    if (countdownOld !== 0) {
                      return toast.error(
                        t(
                          '验证码已发送，请您耐心等待5分钟后再试。感谢您的理解与配合！',
                        ),
                      );
                    }
                    setIsModify(true)
                    // type是后端区分验证码在哪里发送的
                    sendMailApiRequest({
                      areaCode: (currentAreaCode as any)?.areaCode,
                      sendType: VerifiedCodeTypeEnum.PHONE,
                      to: user?.phone.trim(),
                      type: VerifiedCodeEnum.UPDATE_PROFILE,
                    });
                  }}
                  className="focus:outline-none text-darkBrandColor !bg-[transparent] text-base w-[60px] absolute right-1 top-[50%] translate-y-[-50%] cursor-pointer"
                  type="button"
                >
                  {countdownOld !== 0 ? (
                    <span className="text-foreground ">
                      {Math.round(countdownOld / 1000)}
                    </span>
                  ) : (
                    t('发送')
                  )}
                </Button>
              </div>
            )}
            {/* 邮箱/手机号输入框  【已設置手機號】 */}
            {user?.phone && (
              <div className="flex items-center bg-backgroundAuxiliaryColor  !border-0  border-borderColor rounded-md mt-4">
                <div
                  className="flex items-center  h-full sm:cursor-pointer"
                  onClick={() => {
                    history.push(
                      `${PageEnum.CHOOSE_AREA}?targetUrl=${
                        location.pathname
                      }&isKeepAlive=${1}`,
                    );
                  }}
                >
                  <Image
                    classNames={{
                      wrapper:
                        'w-[20px] ml-2  h-[20px] flex-shrink-0 rounded-full object-cover',
                    }}
                    className="w-[20px] h-[20px] flex-shrink-0 rounded-full  object-cover"
                    src={(currentAreaCode as any)?.iconUrl}
                  />
                  <span>+{(currentAreaCode as any)?.areaCode}</span>
                  <FluentTriangleDown12Filled
                    className="text-backContrastColor ml-2"
                    width="8px"
                    height="8px"
                  />
                </div>
                <BaseInput
                  value={newPhone}
                  classNames={{
                    inputWrapper:
                      ' bg-backgroundAuxiliaryColor  !border-0 !shadow-none',
                  }}
                  onChange={(e) => setNewPhone(e.target.value)}
                  isRequired
                  label={t('新手机号码')}
                />
              </div>
            )}
            {/* 验证码输入框 */}
            <div className=" relative mt-4">
              <BaseInput
                value={captcha}
                onChange={(e) => setCaptcha(e.target.value)}
                isRequired
                type="text"
                className="mt-4"
                isShowClear={false}
                classNames={{
                  clearButton: 'block opacity-100',
                  inputWrapper:
                    ' bg-backgroundAuxiliaryColor  !border-0 !shadow-none',
                }}
                label={user?.phone?t("新手机验证码"):t('验证码')}
              />
              <Button
                spinner={<LoadingSvg />}
                isLoading={!isModify&&sendMailLoading}
                onClick={() => {
                  if (countdown !== 0) {
                    return toast.error(
                      t(
                        '验证码已发送，请您耐心等待5分钟后再试。感谢您的理解与配合！',
                      ),
                    );
                  }
                  if (!newPhone.trim()) {
                    return toast.error(t('请输入手机号'));
                  }

                  // type是后端区分验证码在哪里发送的
                  sendMailApiRequest({
                    areaCode: (currentAreaCode as any)?.areaCode,
                    sendType: VerifiedCodeTypeEnum.PHONE,
                    to: newPhone.trim(),
                    type: VerifiedCodeEnum.UPDATE_PROFILE,
                  });
                }}
                className="focus:outline-none text-darkBrandColor !bg-[transparent] text-base w-[60px] absolute right-1 top-[50%] translate-y-[-50%] cursor-pointer"
                type="button"
              >
                {countdown !== 0 ? (
                  <span className="text-foreground ">
                    {Math.round(countdown / 1000)}
                  </span>
                ) : (
                  t('发送')
                )}
              </Button>
            </div>

            {/*提交*/}
            <Button
              className="mainColorButton w-full mt-4"
              onClick={() => {
                modifyMobilePhoneNumber();
              }}
            >
              {t('确认修改')}
            </Button>
          </div>
        </div>
      </BaseModal>
    </>
  );
};

interface IProps {
  user: any; // 用户信息
  children: ReactNode;
  newPhone: string;
  setNewPhone: (value: string) => void;
  oldCaptcha: string;
  setOldCaptcha: (value: string) => void;
  captcha: string;
  setCaptcha: (value: string) => void;
  countdownOld: number;
  countdown: number;
  modifyMobilePhoneNumber: () => void;
  setTargetDateOld: (value: number) => void;
  setTargetDate: (value: number) => void;
}
