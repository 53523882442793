import BaseTopNav from '@/components/base/baseTopNav';
import ModifyUserInformationForm from '@/pages/mine/modifyUserInformation/components/ModifyUserInformationForm';
import { useTranslation } from 'react-i18next';
import {history} from "@umijs/max";
import PageEnum from "@/enums/pageEnum";

export default () => {
  const { t } = useTranslation();
  return (
    <>
      <BaseTopNav title={t('修改个人信息')} onBack={()=>{
          history.replace(PageEnum.MINE)
      }}/>
      <ModifyUserInformationForm  />
    </>
  );
};
